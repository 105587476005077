var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", {
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "rtb-d-flex items-center justify-end h-full ml-auto",
                  },
                  [
                    _c("rtb-control-button", { on: { click: _vm.close } }, [
                      _vm._v(" Back "),
                    ]),
                    _vm.user
                      ? _c(
                          "rtb-control-button",
                          {
                            staticClass: "ml-2",
                            on: {
                              click: function ($event) {
                                return _vm.$navigator.navigateTo("/logout")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("UserName", {
                                  attrs: {
                                    firstname: _vm.user.firstname,
                                    lastname: _vm.user.lastname,
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { opacity: "40%" } },
                                  [_vm._v(" (logout)")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !!_vm.client
        ? _c("ClientSettings", {
            attrs: { client: _vm.client, clientID: _vm.clientID },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }