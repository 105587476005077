<template>
  <div>
    <Header>
      <template #right>
        <div class="rtb-d-flex items-center justify-end h-full ml-auto">
          <rtb-control-button @click="close"> Back </rtb-control-button>
          <rtb-control-button
            v-if="user"
            @click="$navigator.navigateTo('/logout')"
            class="ml-2"
          >
            <span>
              <UserName :firstname="user.firstname" :lastname="user.lastname" />
              <span style="opacity: 40%"> (logout)</span>
            </span>
          </rtb-control-button>
        </div>
      </template>
    </Header>
    <ClientSettings
      v-if="!!client"
      :client="client"
      :clientID="clientID"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import { RtbLink } from "@/components/ui"

import ClientSettings from "@/components/Game/ClientSettings"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import Header from "@/components/GroupTeams/Common/Header"
import { RtbControlButton } from "@/components/ui"

let path

export default {
  name: "SessionSettings",
  components: {
    RtbLink,
    ClientSettings,
    RtbControlButton,
    UserName,
    Header
  },
  beforeRouteEnter(_, from, next) {
    path = from
    next()
  },
  computed: {
    ...mapGetters("auth", ["user", "clientID", "client"])
  },
  methods: {
    close() {
      this.$router.push(path)
    }
  }
}
</script>
